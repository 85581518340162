import React from 'react';
import classes from './Profile.module.css';

import etherIcon from '../assets/ether.png';
import dappIcon from '../assets/dapp.png';
import metaMaskIcon from '../assets/icon.png';

import { xBalance,  xUSDZAddress,xUSDTAddress, xNullAddress  } from '../X';
import { z } from '../Z';



const Profile = (props) => {

    const nano = (p) =>  {      return Math.round(p * 10**(9-18));    }

//    const milliEth = (p) => {      return Math.round(p * 10**(3-props.priceDecimals));    }

    const rc = (p,d,c) => { let a = p / 10**d; let i = Math.floor(a); let f = a-i; return ( i.toLocaleString('en-US') + f.toFixed(c).toString().substring(1) )  }
    const rcRock   = (p) =>      {      return  rc( p, props.info.decimals,3).replace(/0+$/,"").replace(/\.$/,"");    }

    const rcPrice  = (p) =>      {      return  rc( p, props.priceDecimals,2);    }
    const rcPrice6 = (p) =>      {      return  rc( p, props.priceDecimals,6);    }



    const saleUri = "/sale/?v:" + props.account; //friendAddress;
    const saleRegUri = "/form/reg/"+ props.friendInvites +"/" + props.account; //friendAddress;
    
    const etherScan = ( networkId, type ) => {
      if (networkId === 11155111) {
        return "https://sepolia.etherscan.io/"+type+"/";
      }
      if (networkId === 4) {
        return "https://rinkeby.etherscan.io/"+type+"/";
      }
      if (networkId === 5) {
        return "https://goerli.etherscan.io/"+type+"/";
      }
      if (networkId === 1) {
        return "https://etherscan.io/"+type+"/";
      }
    }


    let d = (new Date(props.info.blockTime * 1000)).toLocaleString();
//    let dl = (new Date(props.info.lastBlocking * 1000)).toLocaleString();

    const datum = (s) => {
           let dt = new Date(s * 1000);
           return (
                    dt.getDate()+
                "/"+(dt.getMonth()+1)+
                "/"+dt.getFullYear()+
                " "+dt.getHours()+
                ":"+dt.getMinutes()+
                ":"+dt.getSeconds()
              );
      }

    let web3Dotted = "" + window.location.href.substring(0,22) + "...";


    const addToWallet = ( ) => {


/*
      if (props.network.id != 5) {
        return;
      }
*/
      let web3 = window.web3;

      web3.eth.currentProvider.sendAsync({
        method:'wallet_watchAsset',
        params:{
          'type':'ERC20',
          'options':{
            'address': xUSDTAddress[props.network.id], //0xf72C1fC27265DA10139531eaBf9D3fb4e8bD1F4D',
            'symbol': "USDT",
            'decimals': 6,
            'image': "https://etherscan.io/token/images/tether_32.png"
          },
       },
       id:Math.round(Math.random()*100000)
      }, function(err,data){
        if(!err) {
          if(data.result){
             console.log('Token added');
           }else{
             console.log(data);
             console.log('Some error');
           }
         } else {
           console.log(err.message);
         }
       });
    }

    const addToWalletZ = ( ) => {


      let web3 = window.web3;

      web3.eth.currentProvider.sendAsync({
        method:'wallet_watchAsset',
        params:{
          'type':'ERC20',
          'options':{
            'address': xUSDZAddress[props.network.id], //'0xE4b70F9DdBb286f3cFA836E0fD970479f3193252',
            'symbol': "USDZ",
            'decimals': 10,
            'image': "https://ares-coin.ae/info/usdz128.png"
          },
       },
       id:Math.round(Math.random()*100000)
      }, function(err,data){
        if(!err) {
          if(data.result){
             console.log('Token added');
           }else{
             console.log(data);
             console.log('Some error');
           }
         } else {
           console.log(err.message);
         }
       });
    }

/*
    <li> invited you: <b>{props.friendAddress === '' ? "is missing" : props.friendAddress }</b>{ props.friendExists ? "" : "(has not mined ARES yet)"}</li>
    <li> invited by you: <ul>

      <div className={classes.header}>
        <p><br/></p>
      </div>

    */
  return (
    <div className={classes.profile}>
      <div className={classes.info} width="200">
        <img alt={ z.title } src={  z.icon128 } width="80px"/>
        <p>Contract</p>
        <ul>
        <li> symbol:     <b>{props.info.symbol}</b></li>
        <li> name:     <b>{props.info.name}</b></li>
        <li> address: <b><a href={etherScan(props.network.id,"address") + props.contract._address} target="scan"  rel="noopener noreferrer" >{props.contract._address}</a></b></li>
        <li> decimals:  <b>{props.info.decimals}</b></li>
        <li> cryptoVersion:  <b>{props.info.ver}</b></li>
        <li> totals: <b>{rcRock( parseInt(props.info.totals[0]) + parseInt(props.info.totals[1]))}</b> Coins</li>
        <li> staked: <b>{rcRock(props.info.totals[1])}</b> Coins</li>
        <li> stakedCapped: <b>{rcRock(props.info.totals[5])}</b> Coins digCapped</li>
        <li> stakedHodled: <b>{rcRock(props.info.totals[6])}</b> Coins hodled</li>
        <li> totalEther: <b>{ (props.info.totalFlowEther / 10**(18-3)).toLocaleString('en-US') }</b> mETH</li>
        <li> current APY: <b>{props.info.apy ? props.info.apy.rate / 10 : "n/a"}</b>% (valid till { props.info.apy ?  datum(props.info.apy.till): "n/a" })</li>
        <li> invited you: <b>{props.friendAddress === '' ? "is missing" : props.friendAddress }</b></li>
        </ul>
        { props.friendReal ? (
          <div>
            <p>Friends</p>
            <ul>
            { props.subs.childs.length > 0  && props.subs.balances.length > 0 ?
                props.subs.childs.map((address,idx) => (
                  <li><b>{props.subs.balances[idx][xBalance.digQual] > 0 ? "★" : "ο"}</b>&nbsp;{  address.substring(0,6) + "..." + address.substr(-4) }&nbsp;:&nbsp;
                   <b style={{color: "white"}}>{props.subs.balances[idx][xBalance.digSum] / 10**props.info.decimals}</b> digged,&nbsp;
                   <b style={{color: "white"}}>{rcPrice(props.subs.balances[idx][xBalance.authorized])}</b> {props.priceSymbol} prov;
                   &nbsp;friends:&nbsp;
                   <b >{props.subs.balances[idx][xBalance.digQualChildCount]}</b> q,&nbsp;
                   <b >{props.subs.balances[idx][xBalance.digSumChild] / 10**props.info.decimals}</b> d
                  </li>
                ))
                :
                <b>nobody</b>
            }
            <li></li>
            <li> qualified friends: <b>{props.balances ? props.balances[xBalance.digQualChildCount] : "n/a" }</b></li>
            <li> digged by friends: <b>{ props.balances ? rcRock(props.balances[xBalance.digSumChild])  : "n/a" } Coins</b></li>
            </ul>
          </div>
         ) : (
           <div>
            <p>YOU NEED AN INVITATION</p>
            <ul>
            <li> ask your friends around the world to be invited. Join and Earn!
                  </li>
            </ul>
            <p><br/></p>
          </div>
        )}
        <p>Balances</p>
        <ul>
        <li> free: <b>{props.balances ? rcRock(props.balances[xBalance.erc20]) : "n/a"}</b> Coins</li>
        <li> enclosed: <b>{
           props.info.isProtected ?
              "unknown"
              :
              (
                props.balances ? rcRock(props.balances[xBalance.blocked]) : "n/a"
              )
          }</b> Coins</li>
        <li> digged: <b>{props.balances ? rcRock(props.balances[xBalance.digSum]) : "n/a" }</b> Coins</li>
        <li> hidden: <b>{props.info.isProtected ? "yes, hidden" : "no" }</b></li>
        <li> qualified: <b>{props.balances ? ( props.balances[xBalance.digQual] > 0 ? datum(props.balances[xBalance.digQual]) : "not yet") : "n/a" }</b></li>
        <li> capped: <b>{props.balances ?  rcRock(props.balances[xBalance.digSumCapped]) : "n/a" }</b></li>
        <li> hodled: <b>{props.balances ?  rcRock(props.balances[xBalance.digSumHodled]) : "n/a" }</b></li>
        </ul>
        <p>CashFlow</p>
        <ul>
        <li> currently: <b>{props.balances ? rcRock(props.balances[xBalance.summarized]) : "n/a" }</b> Pebbles</li>
        <li> resolved: <b>{props.balances ? rcRock(props.balances[xBalance.summarizedTotal]) : "n/a" }</b> Pebbles</li>
        <li> earned: <b>{
          props.info.isProtected ?
             "unknown"
             :
             (
              props.balances ? rcPrice6(props.balances[xBalance.summarizedAPY]).toLocaleString('en-US') : "n/a"
             )
          }</b> {props.priceSymbol}</li>
        <li> total: <b style={{color: "white"}}>{props.balances ? rcPrice6(props.balances[xBalance.summarizedTotalAPY]).toLocaleString('en-US') : "n/a" }</b> {props.priceSymbol}</li>
        <li> authorized: <b style={{color: "white"}}>{props.balances ? rcPrice(props.balances[xBalance.authorized]).toLocaleString('en-US'): "n/a" }</b> {props.priceSymbol}</li>

        <li> current APY: <b>{props.info.apy ? props.info.apy.rate / 10 : "n/a"}</b>% (valid till { props.info.apy ?  datum(props.info.apy.till): "n/a" })</li>

        <li> last time rocked: <b>{ props.balances ? (props.balances[xBalance.lastBlockingTime] === 0  ? "not rocked yet" : datum(props.balances[xBalance.lastBlockingTime]))  : "n/a" }</b></li>


        </ul>
        <p><br/></p>
        {
          window.ether.isMetaMask ?
            <img alt="Metamask" src={metaMaskIcon} width="80px"/>
            :
            ""
        }
        <p>Wallet</p>
        <ul>
        <li> address: <b><a href={etherScan(props.network.id,"address") + props.account} target="scan"  rel="noopener noreferrer" >{props.account}</a></b></li>
        <li> name: <b>
          { window.ether.isMetaMask ? "Metamask" : (
             window.ether.isCoinbaseWallet ? "Coinbase Wallet" : (
               window.ether.isTrustWallet ? "Trust Wallet" : (
                 "Unknown Wallet"
               )
            )
          )
          }
        </b></li>
        </ul>
        <p><br/></p>
        <img alt="Ethereum" src={etherIcon} width="50px"/>
        <p>Ethereum Network</p>
        <ul>
        <li> network: <b>{props.network.name}</b> (id: <b>{props.network.id}</b>)</li>
        <li> web3: <b>{web3Dotted}</b></li>
        <li> gasprice: <b><a href={ "https://ethereumprice.org/gas/"} target="gas" rel="noopener noreferrer">{nano(props.gasPrice)}nETH or GWEI</a></b></li>
        <li> blockTime: <b>{props.info.blockTime}</b></li>
        <li> blockDate: <b>{  d }</b></li>
        </ul>
        <p><br/></p>
        <img alt="Dapp" src={dappIcon} width="100px"/>
        <p>Principle of Decentralized App</p>
        <div><p>
          This decentralized Application (<b>DApp</b>) is provided <u>free of charge</u> by the <b><a style={{color: "white"}} href="javascript:window.location.href+='&origin'">Interactive Software Development LLC</a></b>.
          It does not interact with third services or stores your data.
          <br/>
          <br/>
          This <b>DApp</b> communicates with your <b>Wallet</b> only. Your <b>Wallet</b> communicates with the <b>Ethereum Network</b>.
          You, the user, has full control of any operation of this <b>DApp</b>. You allow read access on your <b>Wallet</b> on start.
          Every transaction that is made by this <b>DApp</b> on your <b>Wallet</b>, needs explicit confirmation by You.
          <br/>
          <br/>
          Time, Place, Fees and Costs of transactions may vary depending on runtime parameters of the <b>Contracts</b>, the <b>Ethereum Network</b>,
           your <b>Wallet</b> and any <b>Ethereum Provider</b> between <b>Wallet</b> and the <b>Ethereum Network</b>.
          <br/>
          </p>
        </div>
        <p>Disclaimer</p>
        <div>
        <p>
          THIS <b>DAPP</b>, CONTENT AND SERVICES ARE PROVIDED "AS IS" AND "AS AVAILABLE." INTERACTIVE SOFTWARE DEVELOPMENT LLC AND ITS
          SERVICE PROVIDERS DO NOT WARRANT OR GUARANTEE THE ACCURACY, COMPLETENESS, OR ADEQUACY OF THIS <b>DAPP</b> OR ANY CONTENT OR
          SERVICES AND DO NOT ENDORSE THE VIEWS OR OPINIONS THAT MAY BE EXPRESSED IN THE CONTENT OR OTHER DATA, INFORMATION,
           OR USER CONTENT THAT MAY BE PROVIDED THROUGH THIS <b>DAPP</b>. INTERACTIVE SOFTWARE DEVELOPMENT LLC AND ITS SERVICE PROVIDERS
           EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND REPRESENTATIONS OF ANY KIND WITH REGARD TO THIS <b>DAPP</b>, CONTENT, SERVICES
           AND OTHER SUBJECT MATTER OF THIS AGREEMENT, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING, WITHOUT LIMITATION,
            ANY WARRANTIES OF FITNESS FOR A PARTICULAR PURPOSE, MERCHANTABILITY, TITLE, OR NON-INFRINGEMENT. NO ORAL OR WRITTEN INFORMATION
            OR ADVICE GIVEN BY INTERACTIVE SOFTWARE DEVELOPMENT LLC, ITS EMPLOYEES, OR SERVICE PROVIDERS WILL INCREASE THE SCOPE OF,
             OR CREATE ANY NEW WARRANTIES. INTERACTIVE SOFTWARE DEVELOPMENT LLC AND ITS SERVICE PROVIDERS MAKE NO WARRANTY THAT
              (a) THIS <b>DAPP</b> WILL MEET YOUR REQUIREMENTS,
              (b) THIS <b>DAPP</b> WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE,
              (c) THE RESULTS THAT MAY BE OBTAINED FROM THE USE OF THIS <b>DAPP</b> WILL BE ACCURATE OR RELIABLE,
              (d) THE QUALITY OF ANY CONTENT OR SERVICES PURCHASED OR OBTAINED BY YOU THROUGH THIS <b>DAPP</b> WILL MEET YOUR EXPECTATIONS, OR
              (e) ANY ERRORS IN THE TECHNOLOGY WILL BE CORRECTED.
            ANY CONTENT OR SERVICES DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THIS <b>DAPP</b> IS DONE AT YOUR OWN DISCRETION AND RISK
            AND YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH MATERIAL.
          </p>
          <p><br/><br/><br/><br/></p>

          <div className={classes.info} width="200">
        <ul>
        <li> Private Sale: <b><a href={saleUri}>SQRS PrivateSale</a></b></li>
        <li><br/></li>
        <li><br/></li>
        <li><br/></li>
        <li> Sale Register: 
          { props.friendInvites == '' ?
            <p className={classes.oops}>Um sich zu registrieren, müssen Sie diese Dapp mit dem Einladungslink aufrufen, den Sie erhalten haben!</p>
            :
            <b><a href={saleRegUri}>SQRS Register Sale</a></b>
          }
        </li>
        <li><br/></li>
        <li><br/></li>
        <li><br/></li>
        <li> Sale Invitation: <b>{props.uriInvite}</b></li>
        </ul>
        </div>
          <p><br/><br/><br/><br/></p>
          <p><br/><br/><br/><br/></p>
          <p><br/><br/><br/><br/></p>
        </div>


      </div>
    </div>
  );
};

export default Profile;


/*
        <a href="#" onClick={addToWalletZ}>Add USDZ to your wallet</a>
        <div className={classes.info} width="200">
        <ul>
        <li> USDZ Address: <b>{xUSDZAddress[props.network.id]}</b></li>
        </ul>
        </div>
        <p><br/><br/><br/><br/></p>
        { props.network.id != 1 ? 
        <a href="#" onClick={addToWallet}>Add USDT to your wallet</a>
        :
        ""
        }
        <p><br/><br/><br/><br/></p>
        <a href="#"         onClick={() => {
          props.failHandler();
        }}
        >FailTest</a>
        <a href="#"         onClick={() => {
          props.payOutHandler();
        }}
        >PayOutTest</a>
        <p><br/><br/><br/><br/></p>
*/