import React, { createRef,useState } from "react";



import classes from './Profile.module.css';

import SignatureCanvas from 'react-signature-canvas';

import { useScreenshot, createFileName } from "use-react-screenshot";



import Text, { Disclaimer } from "./Contract";

import { xNullAddress, xUSDTAddress, xUSDZAddress } from "../X";
import { z } from "../Z";


const Order = (props) => {

  const [inputValue, setInputValue] = useState('');
  const [inputCount, setInputCount] = useState('');

  const [walletAddress, setWalletAddress] = useState('0x0');

  const [anrede, setAnrede] = useState('');
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [street, setStreet] = useState('');
  const [city, setCity] = useState('');

  const [netCost, setNetCost] = useState(0);
  const [netReserve, setNetReserve] = useState(0.05 * 10**18);


  //let pC = props.priceCurrency;
  const pC = window.rckCurrency;

  // limit pieces /inputcount/  to 1000 or to decimals of digged ... whatever is lower, 
  const minDigDecimals = props.info.decimals < 4 ? props.info.decimals : 3;

  const nano = (p) =>  {      return Math.round(p * 10**(9-18));    }

  const priceRound = () => {  return Math.round( 100000*( props.price / 10**props.priceDecimals) )/100000  }

  const ethRound = (p) => { return Math.round( p / 10**12)/10**6 }
  const coinDecimals = props.info.decimals;

  /* screenshot */

  const ref = createRef(null);
  const [image, takeScreenShot] = useScreenshot({
    type: "image/png",
    quality: 1.0
  });

  const getRandomInt = (max) => {
    return Math.floor(Math.random() * max);
  }
  
  const download = (image, { name = z.title + "_order_" + getDate(1), extension = "png" } = {}) => {
/*
    const pdf = new jsPDF("p", "mm", "a4", true);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();
    const imgWidth = window.screen.width;
    const imgHeight = window.screen.height;
    const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
    const imgX = (pdfWidth - imgWidth * ratio) / 2;
    const imgY = (pdfHeight - imgHeight * ratio) / 2;
    pdf.addImage(
      image,
      "PNG",
      imgX,
      imgY,
      imgWidth * ratio,
      imgHeight * ratio
    );
    var doc = pdf.output('datauri');
*/
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
         '_contract.png': image,
         'email': getRandomInt(1000).toString(),
          'ott': getRandomInt(1000)
       })
    };
    
    fetch( z.formSubmitURI, requestOptions)
      .then(response => response.json())
      .then(data => alert("contract submitted"/*, data*/));


    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();

//    alert("Contract stored on device!");

  };

  const downloadScreenshot = () => {
    takeScreenShot(ref.current).then(download);
  };
/*

  const takeScreenShot2 = (r ) => {
    var canvasPromise = html2canvas(r.current, {
      useCORS: true
    });
    canvasPromise.then((canvas)=> {

      var dataURI = canvas.toDataURL("image/png");

      // Create an image element from the data URL
      var image = new Image();
      image.src = dataURI;
      image.download = dataURI;
      

      const pdf = new jsPDF("p", "mm", "a4", true);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
      const imgX = (pdfWidth - imgWidth * ratio) / 2;
      const imgY = (pdfHeight - imgHeight * ratio) / 2;
      pdf.addImage(
        imageURI,
        "PNG",
        imgX,
        imgY,
        imgWidth * ratio,
        imgHeight * ratio
      );

      var docURI = pdf.output('datauri');
  
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
           '_contract.png': imageURI,
           '_contract.pdf': docURI,
           'email': getRandomInt(1000).toString(),
            'ott': getRandomInt(1000)
         })
      };
      
      fetch('https://skylandcc.app/form/', requestOptions)
        .then(response => response.json())
        .then(data => alert("contract submitted"));
  
  
      const a = document.createElement("a");
      a.href = imageURI;
      a.download = createFileName(extension, name);
      a.click();
  
    }

  }
    */






  const inputChangeHandlerAddress = (event) => {
    event.preventDefault();
    setWalletAddress(event.target.value);
  };

  const inputChangeHandlerValue = (event) => {
    event.preventDefault();

    let i = event.target.value;

    if (  /^[0-9]*[.]?[0-9]*$/.test(i) || i === "") {
      let f = parseFloat(i);


      if (i<0) {
        i=0;
        f=0;
      }
      setInputValue(i);

      let cnt = Math.floor( 10**minDigDecimals * (f) / (props.price / 10**props.priceDecimals) ) / 10**minDigDecimals;
      console.log("val=>cnt:",f,"=>",cnt);
      setInputCount(cnt);

      props.inputHandler(cnt);
    } else {
      setInputValue("");
    }
  };

  const inputChangeHandlerCount = (event) => {
    event.preventDefault();

    let i = event.target.value;

    if (  /^[0-9]*[.]?[0-9]*$/.test(i) || i === "") {
      let f = parseFloat(i);


      if (i<0) {
        i=0;
        f=0;
      }
      setInputCount(i);

      let val = Math.floor( 10000* f * props.price / 10**props.priceDecimals)/10000;
      console.log("cnt=>val:",f,"=>",val);
      setInputValue(val);

      props.inputHandler(i);
    } else {
      setInputCount("");
    }
  };

  function getDate(mode) {
    const today = new Date();
    const month = today.getMonth() + 1;
    const year = today.getFullYear();
    const date = today.getDate();

    const hours = today.getHours();
    const minutes = today.getMinutes();
    const seconds = today.getSeconds();

    if (mode == 0) 
      return `${month}/${date}/${year}`;

    return `${year}${month}${date}${hours}${minutes}${seconds}` + props.friendExists;
  }

  const submitRequest = (event) => {

    takeScreenShot(ref.current).then(download);
  //  takeScreenShot2(ref);

}

  if (pC === xNullAddress) {
    console.log("switch currency to ",xUSDTAddress[ props.network.id]);
    props.currencyHandler(xUSDTAddress[ props.network.id]);
  }


/*  props.setAppStatus(false);*/
  
  return (
    <div>
      <br></br>
      <br></br>
      <h1>In any case you can't or wont order on blockchain, you can order by this electronic form.</h1>

    <div className={classes.boxHigh}>
        <p><b> {"CURRENT PRICE: " + priceRound() + " " + props.priceSymbol + " / " + z.ccTitle }</b></p>
    </div>

    <div className={classes.boxOrder}>


      <div> <br/>  </div>
      <div className={classes.Formular}>
        <div>
          <input
            placeholder={z.ccTitle}
            className={classes.input}
            onChange={inputChangeHandlerCount}
            value={inputCount}
          ></input>
          &nbsp;
          <span
            className={classes.inputUnit}          
          >Coins
          </span>
        </div>
        <div>
          <input
            placeholder={props.priceSymbol}
            className={classes.input}
            onChange={inputChangeHandlerValue}
            value={inputValue}
          ></input>
          &nbsp;
          <span
            className={classes.inputUnit}          
          >{props.priceSymbol}
          </span>
        </div>
        <div>
          <input
            placeholder="0x0"
            className={classes.inputWallet}
            onChange={inputChangeHandlerAddress}
            value={walletAddress}
          ></input>
          &nbsp;
          <span
            className={classes.inputUnit}          
          >BuyersWallet
          </span>
        </div>

          <select id="an" value={anrede} onChange={event => setAnrede(event.target.value)}>
            <option value="Mr">Mr</option>
            <option value="Mrs">Mrs</option>
            <option value="Miss">Miss</option>
            <option value="Dr">Dr</option>
          </select>
          <input id="fname"  type="text" placeholder="First name"   value={fname}  onChange={event => setFname(event.target.value)} />
          <input id="lname"  type="text" placeholder="Last name"    value={lname}  onChange={event => setLname(event.target.value)}/>
          <input id="street" type="text" placeholder="Street, Nb"   value={street} onChange={event => setStreet(event.target.value)}/>
          <input id="city"   type="text" placeholder="Postal Code, City, Country" value={city}   onChange={event => setCity(event.target.value)}/>
          <input id="email"  type="text" placeholder="Email"        value={email}  onChange={event => setEmail(event.target.value)}/>
          <input id="mobile" type="tel" placeholder="Mobile number" value={mobile} onChange={event => setMobile(event.target.value)}/>
      <div> <br/>  </div>
    </div>
    </div>
  
  <div
      ref={ref}
      style={{
        border: "1px solid #ccc",
        padding: "10px",
        marginTop: "20px"
      }}
     >

    <div className={classes.boxOrder}>
      <br></br>
      <br></br>
      <h1>Online Purchase Agreement for Commodity-Coins via Bank Transfer</h1>

      <div class="contract-section">
          <h2>Contracting Parties</h2>
          <h3>Seller:</h3>
          <p>Firststone CC Trading DMCC</p>
          <p>Jumeirah Lakes Towers</p>
          <p>Dubai, United Arab Emirates</p>
          <p>License No. DMCC-855512</p>
          
          <h3>Buyer:</h3>
          <p>{fname} {lname}</p>
          <p>{street}</p>
          <p>{city}</p>

          <p>(Mobile: {mobile}, E-mail: {email})</p>


      </div>


      <div class="contract-section">
          <h2>Preamble</h2>
          <p>This contract governs the purchase of Commodity-Coins by the buyer from the seller. Commodity-Coins are digital assets linked to specific commodities.</p>
      </div>

      <div> <br/>  </div>

      <div className={classes.contractOrder}>
      <Text
        count={inputCount}
        price={inputValue}
        symbol={props.priceSymbol}

        description="Selective Project"
        seller="Firststone CC Trading DMCC"
        iban="CH............."
        bic="............"
        bank="............."

        days="6"
        wallet={walletAddress}
        jurisdiction="Dubai"
      />
      </div>

      <div className={classes.Signature}>
      <p>Signature of the Buyer,   {getDate(0)}</p>

      <SignatureCanvas penColor='black' backgroundColor='white'
         canvasProps={{width: 300, height: 150, className: 'sigCanvas'}}
         onEnd={() => { return false }} 
         />
      </div>

      <div className={classes.adviceTxt}>
        <p>Herewith I order {inputCount} {z.ccTitle} Coins for USD {inputValue}. <br/>
           {z.hint}<br/></p>
         <p>Registered Sales ID: {props.friendAddress}</p>  

      </div>

      <div className={classes.xxx}>
        <button
          className={classes.digButton}
          _disabled={!props.friendExists && !props.offlineOverride}
          onClick={() => {
            submitRequest();
          }}
        >
          <p>{ (props.friendExists || 1==1) >0 ? ( inputValue === "" ? "Enter amount to invest" : "I order " + inputCount + " "+ z.ccTitle ) : "This account needs an invitation!" }</p>
        </button>
      </div>

      <div className={classes.contractDisclaimer}>
        <Disclaimer/>
      </div>
    </div>


      <p>
      <br/>
      <br/>
      <br/>
      </p>

    </div>

  </div>
    
  );

};

export default Order;

/*
      <h5 className={classes.goMax}>
        Balance: { Math.floor(props.userBalance * 1000)/1000 } {props.priceSymbol}
        &nbsp;
        &nbsp;
        <button
        className={classes.currencyButton}
          onClick={() => {

            console.log('netid', props.network.id);
            if (pC === xNullAddress) {
              console.log("switch currency to ",xUSDTAddress[ props.network.id]);
              props.currencyHandler(xUSDTAddress[ props.network.id]);
            } else {

              if (pC == xUSDTAddress[props.network.id]) {
                console.log("switch currency to ",xUSDZAddress[ props.network.id]);
                props.currencyHandler(xUSDZAddress[props.network.id]);
              } else {
                console.log("switch currency to ",xNullAddress);
                props.currencyHandler(xNullAddress);
              }
            }
            setInputValue('');
            setInputCount('');
          }}
          >
          Switch&nbsp;to&nbsp;{ pC === xNullAddress ?  "USDT" : ( pC == xUSDTAddress[props.network.id] ? "USDZ" : "ETH") }
        </button>

      </h5>

      */